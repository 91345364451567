import React                   from 'react';
import Img                     from 'gatsby-image';
import { graphql }             from 'gatsby';
import { Container, Row, Col } from 'react-grid-system';

import CallToAction
                         from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import Heading           from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer            from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper           from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import HeaderImage       from '@interness/web-core/src/components/media/HeaderImage/HeaderImage';
import Separator         from '@interness/web-core/src/components/structure/Separator/Separator';
import { findMedia }     from '@interness/web-core/src/components/_helpers';
import Button            from '@interness/web-core/src/components/elements/Button/Button';
import Link              from '@interness/web-core/src/components/elements/Link/Link';
import Accordion         from '@interness/web-core/src/components/structure/Accordion/Accordion';
import LiveAnnouncements from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';
import Lightbox          from '@interness/web-core/src/components/media/Lightbox/Lightbox';
import MoreExpander      from '@interness/web-core/src/components/structure/MoreExpander/MoreExpander';
import ResponsiveIFrame  from '@interness/web-core/src/components/structure/iFrame/iFrame';
import Center            from '@interness/web-core/src/components/structure/Center/Center';

import HeaderImg from '../components/HeaderImg';

function IndexPage(props) {
  return (
    <>
      <HeaderImage>
        <HeaderImg id="home"/>
      </HeaderImage>
      <Wrapper>
        <Spacer/>
        <section>
          <Heading subtitle={'Herzlich Willkommen'}>Pierenkemper Tischlerei und Möbelwerkstätten</Heading>
          <span style={{ textAlign: 'justify' }}>
            <p>Willkommen bei den Möbelwerkstätten Pierenkemper, Ihrer erfahrenen Tischlerei und Schreinerei im Herzen von Halver. Wir sind spezialisiert auf die Herstellung von maßgefertigten Möbeln und Einbaumöbeln, die perfekt auf Ihre Bedürfnisse und Räumlichkeiten abgestimmt sind. Mit Leidenschaft und Handwerkskunst fertigen wir individuelle Maßanfertigungen, die Ihre Wohnräume optimal nutzen und verschönern.</p>
            <p>Unser Service beginnt mit einem präzisen Aufmaß vor Ort, um sicherzustellen, dass Ihre neuen Möbel perfekt in den vorgesehenen Raum passen. Wir legen großen Wert auf eine sorgfältige Planung und Beratung, damit jedes Möbelstück Ihren Vorstellungen entspricht. Unsere Experten beraten Sie umfassend zu Materialien, Design und Funktionalität, um die beste Lösung für Ihren Wohnraum zu finden.</p>
            <p>In unserer Tischlerei Pierenkemper verwenden wir eine Vielfalt an hochwertigen Materialien, um langlebige und ästhetisch ansprechende Möbel zu kreieren. Ob es um die Raumoptimierung mit cleveren Stauraumlösungen geht oder um das perfekte Einzelstück, das Ihr Zuhause bereichert – wir setzen Ihre Ideen mit höchster Sorgfalt um.</p>
            <p>Unser Team vereint traditionelle Handwerkskunst mit modernen Techniken, um Möbel zu schaffen, die nicht nur funktional, sondern auch einzigartig und persönlich sind. Besuchen Sie uns in Halver und erleben Sie, wie wir Ihre Wohnträume wahr werden lassen.</p>
            <p>BIS BALD IN HALVER, IHR PIERENKEMPER TEAM</p>
          </span>
        </section>
        <LiveAnnouncements/>
        {/*<Separator/>*/}
        {/*<section>*/}
        {/*  <Heading>News</Heading>*/}
        {/*  <p>Auf unserer neu eingerichteten Seite "NEWS" möchten wir Sie über aktuelle Projekte informieren. Hier zeigen*/}
        {/*    wir Ihnen Bilder vor Projektstart und nach Durchführung des Projektes. Selbstverständlich wurde uns die*/}
        {/*    Erlaubnis des Auftraggebers gewährt.</p>*/}
        {/*  <div style={{ maxWidth: '450px', textAlign: 'center', margin: 'auto' }}>*/}
        {/*    <Img fluid={findMedia(props.data.media.media, 'news-1').childImageSharp.fluid}/>*/}
        {/*    <Spacer height={20}/>*/}
        {/*    <Button as={Link} to={'/news'}>Mehr erfahren</Button>*/}
        {/*  </div>*/}
        {/*</section>*/}
        <Separator/>
        <section>
          <Heading tag={'h2'}>Was uns unterscheidet</Heading>
          <p>Kompletter Service ist für unser Team selbstverständlich. Darum werden Möbel und Einbauten in unserer
            eigenen Werkstatt hergestellt, umgearbeitet oder instandgesetzt. So erfüllen wir Ihre Wünsche schnell,
            sicher und mit persönlichem Ansprechpartner.</p>
          <Accordion data={props.data.services.nodes}/>
        </section>
        <Separator/>
        <section>
          <Heading tag={'h2'}>Was wir für Sie tun können</Heading>
          <Spacer/>
          <Container>
            <Row>
              <Col md={4}>
                <h3 style={{ textAlign: 'center' }}>Über uns</h3>
                <Img fluid={findMedia(props.data.media.media, 'team').childImageSharp.fluid}/>
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                  <Button as={Link} to={'/ueber-uns'}>Mehr erfahren</Button>
                </div>
              </Col>
              <Col md={4}>
                <h3 style={{ textAlign: 'center' }}>Leistungen</h3>
                <Img fluid={findMedia(props.data.media.media, 'leistungen').childImageSharp.fluid}/>
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                  <Button as={Link} to={'/leistungen'}>Mehr erfahren</Button>
                </div>
              </Col>
              <Col md={4}>
                <h3 style={{ textAlign: 'center' }}>Kontakt</h3>
                <Img fluid={findMedia(props.data.media.media, 'contact-1').childImageSharp.fluid}/>
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                  <Button as={Link} to={'/contact'}>Mehr erfahren</Button>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Separator/>
        <section>
          <Heading tag={'h2'}>Neuigkeiten</Heading>
          <article>
            <MoreExpander title="Gesellenprüfung Philipp Lüttge">
              <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <h3>Gesellenprüfung Philipp Lüttge</h3>
                <ResponsiveIFrame src={`https://www.youtube-nocookie.com/embed/KpQgWNwWYzw?rel=0&amp;controls=0`}
                                  title={`Rolex Wartung`} type={'youtube'}/>
              </div>
            </MoreExpander>
          </article>
          <article>
            <MoreExpander title="Gesellenprüfung Stefen Lepperhoff">
              <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <h3>Gesellenprüfung Stefen Lepperhoff</h3>
                <p>
                  Herzlichen Glückwunsch zur bestandenen Gesellenprüfung Stefen. <br/>
                </p>
              </div>
              <Center>
                <Button primary external as={Link} to={props.data.lepperhoff.media[0].file.localFile.publicURL}>Download
                  PDF</Button>
              </Center>
              <Spacer/>
            </MoreExpander>
          </article>
          <article>
            <MoreExpander title="Gesellenprüfung Mauritio Rost">
              <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <h3>Gesellenprüfung Mauritio Rost</h3>
                <p>
                  Herzlichen Glückwunsch zur bestandenen Gesellenprüfung Mauro. <br/>
                  Klasse gemacht mit Auszeichnung und Gewinn der Guten Form.<br/>
                  Der Name ist Programm, wirklich besonders, kannst stolz darauf sein.
                </p>
              </div>
              <Lightbox images={props.data.rost.images} thumbnails={props.data.rost.thumbnails} fromApi/>
            </MoreExpander>
          </article>
        </section>
        <Spacer/>
        <section>
          <Heading tag={'h2'}>Sicher mit System</Heading>
          <Img fluid={findMedia(props.data.media.media, 'sms-img-0').childImageSharp.fluid}/>
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <Button as={Link} external
                    to={findMedia(props.data.media.media, 'sms-0681-urkunde-pierenkemper-20180104').publicURL}>Mehr
              erfahren</Button>
          </div>
        </section>
        <Separator/>
        <div style={{ textAlign: 'center' }}>
          <CallToAction/>
        </div>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default IndexPage;

export const query = graphql`
    query IndexQuery {
        rost: directusMediaCollection(name: {eq: "gesellenpruefung-rost"}) {
            ...LightboxGalleryGrid
        }
        lepperhoff: directusMediaCollection(name: {eq: "gesellenpruefung-lepperhoff"}) {
            media {
                file {
                    localFile {
                        name
                        publicURL
                    }
                }
            }
        }
        media: directusMediaCollection(name: {eq: "home"}) {
            media {
                file {
                    localFile {
                        name
                        publicURL
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        services: allServicesYaml {
            nodes {
                text
                title
            }
        }
    }
`;